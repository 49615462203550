<template>
  <div
    v-if="material && Object.keys(material).length"
    target="_blank"
    @click="linkClick(material.link || $root.host + material.file.url, kind === 'file' ? material.link_title || material.name : false)"
    class="material"
  >
    <div class="material__title" v-html="material.title || material.name"></div>
    <div class="material__link">
      <div class="material__link-icon">
        <svg
          v-if="kind === 'link'"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333"
            stroke="#D0006F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 2H14V6"
            stroke="#D0006F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.6665 9.33333L13.9998 2"
            stroke="#D0006F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-else-if="kind === 'file'"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.33317 1.3335H3.99984C3.64622 1.3335 3.30708 1.47397 3.05703 1.72402C2.80698 1.97407 2.6665 2.31321 2.6665 2.66683V13.3335C2.6665 13.6871 2.80698 14.0263 3.05703 14.2763C3.30708 14.5264 3.64622 14.6668 3.99984 14.6668H11.9998C12.3535 14.6668 12.6926 14.5264 12.9426 14.2763C13.1927 14.0263 13.3332 13.6871 13.3332 13.3335V5.3335L9.33317 1.3335Z"
            stroke="#D0006F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.3335 1.3335V5.3335H13.3335"
            stroke="#D0006F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.6668 8.6665H5.3335"
            stroke="#D0006F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.6668 11.3335H5.3335"
            stroke="#D0006F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.66683 6H6.00016H5.3335"
            stroke="#D0006F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      {{
        material.link_title ||
        (material.file ? material.file.extension.toUpperCase() : "")
      }}
      <span
        v-if="material.file && material.file.size"
        class="material__link-weight"
        >{{ material.file.size }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "FileBlock",
  props: {
    material: Object,
    kind: {
      type: String,
      default() {
        return this.material.kind
      } 
    }
  },
  methods: {
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split('.').pop().trim()
    },
    linkClick(href, download) {
      this.$emit('linkClick')
      let a = document.createElement('a')
      a.href = href
      a.download = download+
          '.' +
          this.get_url_extension(href)
      a.target = '_blank'
      document.body.append(a)
      a.click()
      a.remove()
    }
  }
};
</script>

<style lang="scss" scoped>
.material {
  padding: 16px;
  background-color: #f8f8f8;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    .material__title {
      color: #d0006f;
    }
  }

  &__title {
    margin-bottom: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
    transition: 0.3s;
  }

  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: #d0006f;
    text-decoration: none !important;

    &-icon {
      margin-right: 8px;
    }

    &-weight {
      margin-left: 8px;
      color: #ec99c5;
    }
  }
}
</style>